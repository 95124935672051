import { defineStore } from "pinia";

export const sessionStore = defineStore('session', {
    state: () => ({
        historicoFiltros: {},
        historicoResultados: [],

        tokenConsultaSuperbox: '',
        prefTooltipSuperbox: true,
        prefDadosOcultosSuperbox: false,
    }),
    actions: {
        
    }
})
